import { parentsUntil } from "@syncfusion/ej2-vue-grids";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import EventBus from "../../../../helpers/EventBus";

const mixin = {
  data() {
    return {
      itemSelected: {},
      rowIndexToExpand: null,
    };
  },
  methods: {
    getItemSelectedIdKey() {
      return "id";
    },
    // MUST be overriden on component that use this mixing
    getSelectedParentKey() {
      return "";
    },

    // MUST be overriden on component that use this mixing
    getSelectedChildrenKey() {
      return "";
    },

    getSelected() {
      const selectedParents = this.$refs.grid.getSelectedRecords();

      const parents = [];
      const children = [];

      const { itemSelected } = this;

      selectedParents.forEach((entity) => {
        const id = get(entity, this.getItemSelectedIdKey());
        if (itemSelected[id] && itemSelected[id].length === 0) {
          parents.push(entity);
        }
      });

      for (const prop in itemSelected) {
        const value = itemSelected[prop];
        children.push(...value);
      }

      if (parents.length > 0 || children.length > 0) {
        const tmp = {};
        tmp[this.getSelectedParentKey()] = parents;
        tmp[this.getSelectedChildrenKey()] = children;
        return tmp;
      }

      return null;
    },

    mapSelectedToIds(selected, mapping = {}) {
      const tmp = {};
      for (const prop in selected) {
        const mappingKey = mapping[prop] || "id";
        tmp[prop] = selected[prop].map((r) => get(r, mappingKey));
      }
      return tmp;
    },

    onDataBound() {
      const records = this.$refs.grid.getCurrentViewRecords();
      const tmp = {};
      for (const record of records) {
        tmp[get(record, this.getItemSelectedIdKey())] = [];
      }
      this.itemSelected = Object.assign({}, tmp);

      if (this.rowIndexToExpand !== null) {
        this.$refs.grid.ej2Instances.detailRowModule.expand(
          this.rowIndexToExpand
        );
        this.rowIndexToExpand = null;
      }
    },

    onDetailDataBound(args) {
      EventBus.$emit("orders:parentDetailDataBound", args);
    },

    onDetailsSelected({ parentId, selected }) {
      const tmp = {};
      tmp[parentId] = selected;
      this.itemSelected = Object.assign({}, this.itemSelected, tmp);
    },

    rowSelectedHandler(args) {
      if (!args.isHeaderCheckboxClicked) {
        if (args.isInteracted) {
          EventBus.$emit("orders:rowSelected", args);
        }
      } else {
        EventBus.$emit("orders:rowSelectedAll", args);
      }
    },

    rowDeselectedHandler(args) {
      if (!args.isHeaderCheckboxClicked) {
        if (args.isInteracted) {
          EventBus.$emit("orders:rowDeselected", args);
        }
      } else {
        EventBus.$emit("orders:rowDeselectedAll", args);
      }
    },

    onUpdateStatusSuccess() {
      EventBus.$emit("orders:refresh");
    },

    bindRowDetailsEvents() {
      EventBus.$on(
        "orders:parentDetailDataBound",
        this.onParentDetailDataBound
      );
    },

    unbindRowDetailsEvents() {
      EventBus.$off(
        "orders:parentDetailDataBound",
        this.onParentDetailDataBound
      );
    },

    onParentDetailDataBound(args) {
      if (args.data.id === this.data.id) {
        let parentRow = parentsUntil(
          args.detailElement,
          "e-detailrow"
        ).previousSibling;
        const rowIndex = parseInt(parentRow.getAttribute("aria-rowindex"), 10);
        this.parentRowIndex = rowIndex;
      }
    },

    onDetailPriceUpdate(payload) {
      const { response, weightEntryData } = payload;
      const { pdetail } = response;
      const { parentRowIndex } = weightEntryData;
      this.rowIndexToExpand = parentRowIndex;

      const idx = this.rows.findIndex((item) => item.id === pdetail.id);
      if (idx !== undefined) {
        this.rows[idx] = {
          ...this.rows[idx],
          price: pdetail.price,
          price_p: pdetail.price_p,
          need_w: pdetail.need_w,
          qta: pdetail.qta,
        };
        this.rows = cloneDeep(this.rows);
      }
    },
  },
};

export default mixin;
